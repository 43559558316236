.leaflet-container {
  width: 100vw;
  height: 100vh;
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

/* TODO: this is only to hack CARTO Positron, not for other maps
.leaflet-tile {
  -webkit-filter: brightness(65%) contrast(420%);
  filter: brightness(65%) contrast(420%);
}
*/

/* TODO: inline these CSS using ThemeProvider hack, see ViolationPopup */
.nfz-popup {
  text-align: center;
}

.nfz-popup-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
}

.nfz-popup-content {
  font-size: 12px;
  font-weight: bold;
  color: rgb(110, 110, 110);
}

.nfz-polygon-pulse {
  animation: 2s pulse 10;
}

/* Hide unwanted scrollbars on Firefox/IE */
#root {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Hide unwanted scrollbars on Chrome, Edge, Safari */
::-webkit-scrollbar {
  display: none;
}

/* This is for EditFeatureGroupGeoman, style not applied locally use withStyles for some reason
  TODO: find out 
*/
.custom-save-icon-leaflet {
  background-image: url('./assets/save.svg');
}
.custom-load-icon-leaflet {
  background-image: url('./assets/map.svg');
}

.leaflet-control-mouseposition {
  margin-bottom: 5px !important;
  margin-left: 5px !important;
}